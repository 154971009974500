'use client';

import { FirebaseApp as FBApp, FirebaseOptions, initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getDatabase, connectDatabaseEmulator } from 'firebase/database';
import { connectFunctionsEmulator, getFunctions, Functions } from 'firebase/functions';

interface AppProps extends FirebaseOptions {
  name: string;
}

interface FirebaseAppOptions {
  disableFunctionsEmulator?: boolean;
  disableAuthEmulator?: boolean;
  host?: string;
  functionsPort?: number;
  databasePort?: number;
  authPort?: number;
}

export class FirebaseApp {
  public readonly functions: Functions;
  public readonly app: FBApp;
  public readonly getFunctionURL: (name: string, hash: string) => string;
  public readonly getAuth = () => getAuth(this.app);

  constructor({ name, ...appConfig }: AppProps, options?: FirebaseAppOptions) {
    const app = initializeApp(appConfig, name);
    const appHost = options?.host || '127.0.0.1';
    const functionsPort = options?.functionsPort || 4002;

    if (!options?.disableFunctionsEmulator) {
      const db = getDatabase(app);

      connectFunctionsEmulator(getFunctions(app), appHost, functionsPort);
      connectDatabaseEmulator(db, appHost, options?.databasePort || 9010);
    }

    if (!options?.disableAuthEmulator) {
      connectAuthEmulator(getAuth(app), `http://${appHost}:${options?.authPort || 9011}`);
    }

    this.functions = getFunctions(app);
    this.getAuth = () => getAuth(this.app);
    this.app = app;
    this.getFunctionURL = (name: string, hash: string) =>
      (options?.disableFunctionsEmulator
        ? `https://${name.toLocaleLowerCase()}-${hash}-uc.a.run.app/`
        : `http://${appHost}:${functionsPort}/${appConfig.appId}/us-central1/${name}`);
  }
}
