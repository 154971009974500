import { MetaPixel } from '@shared/trackit';

import { useFirebaseAnalytics, FirebaseEventParams } from './useFirebaseAnalytics';

interface CheckoutPurchase {
  orderId: string;
  currency: string;
  value: number;
  items: Array<{
    item_id: string;
    item_name: string;
  }>;
  purchaseType: 'initial' | 'upsell';
}

export const useTracking = () => {
  const { visit, log, setUserProperties } = useFirebaseAnalytics();

  const visitPlans = (params?: FirebaseEventParams) => {
    visit('plans', params);

    MetaPixel().ViewContent();
  };

  const visitCheckout = (params?: FirebaseEventParams) => {
    visit('checkout', params);

    MetaPixel().InitiateCheckout();
  };

  const quizStart = (params?: FirebaseEventParams) => log('quiz_start', params);
  const quizFinish = (params?: FirebaseEventParams) => log('quiz_finish', params);
  const plansSelect = (params: FirebaseEventParams) => log('plans_select', params);
  const checkoutSubmit = (params: FirebaseEventParams) => log('checkout_submit', params);

  const quizQuestionAnswer = (params: FirebaseEventParams) => log('quiz_question_answer', params);

  const emailLeft = (params: Partial<FirebaseEventParams> & { leadId: string }) => {
    setUserProperties({ id: params.leadId });
    log('email_left', { category: 'email', label: 'left', ...params });

    MetaPixel().Lead({ eventId: params.leadId });
  };

  const checkoutPurchase = (params: Partial<FirebaseEventParams> & CheckoutPurchase) => {
    const { value, currency, orderId, items, purchaseType } = params;

    log('purchase', {
      transaction_id: orderId,
      currency,
      value,
      items,
      category: `purchase_${purchaseType}`,
    });

    MetaPixel().Purchase({ value, currency, eventId: orderId });
  };

  return {
    visitPlans,
    visitCheckout,
    quizStart,
    quizFinish,
    quizQuestionAnswer,
    checkoutSubmit,
    checkoutPurchase,
    plansSelect,
    emailLeft,
  };
};
