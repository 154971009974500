import { logEvent, getAnalytics, setUserProperties as setUProperties, setUserId } from 'firebase/analytics';
import { useCallback } from 'react';

import { firebaseApp } from '~/firebase-init';

export type FirebaseEvent =
  | 'page_view_init'
  | 'checkout_submit'
  | 'plans_select'
  | 'email_left'
  | 'quiz_start'
  | 'quiz_finish'
  | 'quiz_question_answer'
  | 'purchase';

export interface FirebaseEventParams {
  category: string;
  label: string;
  [key: string]: unknown;
}

interface UserProperties {
  id: string;
}

export const useFirebaseAnalytics = () => {
  const log = useCallback((eventName: FirebaseEvent, eventParams?: Partial<FirebaseEventParams>) => {
    logEvent(getAnalytics(firebaseApp.app), eventName as string, eventParams);
  }, []);

  const visit = (page: string, params: FirebaseEventParams = { category: 'pageview', label: 'visit' }) =>
    logEvent(getAnalytics(firebaseApp.app), `${page}_visit`, params);

  const setUserProperties = (properties: UserProperties) => {
    const { id, ...restProperties } = properties;

    if (id) {
      setUserId(getAnalytics(firebaseApp.app), id);
    }

    if (Object.values(restProperties).some(Boolean)) {
      const filteredProperties: Record<string, string> = {};

      for (const key in restProperties) {
        if (properties[key as keyof UserProperties]) {
          filteredProperties[key] = properties[key as keyof UserProperties] as string;
        }
      }

      setUProperties(getAnalytics(firebaseApp.app), filteredProperties);
    }
  };

  const initialLoad = useCallback(() => log('page_view_init'), [log]);

  return {
    log,
    setUserProperties,
    visit,
    initialLoad,
  };
};
