import { Lead } from './types';

export const DEFAULT_LEAD_VALUE: Lead = {
  id: undefined,
  email: undefined,
  selectedPlan: undefined,
  isMetric: false,
  quizAnswers: {
    height: '',
    weight: '',
    targetWeight: '',
    age: '',
    gender: 'female',
    cardio_time_dedication: '',
    how_often_do_you_exercise: '',
  },
};
