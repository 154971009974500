import { inputAnatomy } from '@chakra-ui/anatomy';
import { extendTheme, createMultiStyleConfigHelpers } from '@chakra-ui/react';

export const colors = {
  primary: '#7301B9',
  primaryLight: '#FDF5EB',
  secondary: 'blue',
  error: '#E90909',
  green: '#17BA13',
  blue: '#4277FF',
  black: '#222222',
  black90: '#222222e6',
  black60: '#22222299',
  black50: '#22222280',
  black40: '#22222266',
  black10: '#2222221a',
};

const textStyles = {
  quizQuestion: {
    fontFamily: 'var(--chakra-fonts-heading)',
    fontSize: ['1.75rem', '2.5rem'], // 28px / 40px
    lineHeight: '100%',
    textAlign: ['inherit', 'center'],
    marginBottom: ['1rem', '2rem'],
    marginX: 'auto',
    maxWidth: '44rem',
    width: '100%',
  },
  h4: {
    fontFamily: 'var(--chakra-fonts-heading)',
    fontSize: '1.375rem', // 22px
    lineHeight: '100%',
  },
};

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

export const theme = extendTheme({
  fonts: {
    body: `sans-serif`,
    heading: `'Orelega One', sans-serif`,
  },
  styles: {
    global: {
      a: {
        flex: 1,
        display: 'flex',
      },
      ul: {
        listStylePosition: 'inside',
      },
      p: {
        lineHeight: '130%',
      },
    },
  },
  colors,
  textStyles,
  components: {
    Button: {
      baseStyle: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        gap: '0.625rem',
        cursor: 'pointer',
        border: 'none',
        boxShadow: '0px 1px 3px 0px #00000012, 0px 5px 12px 0px #0000001A',
        color: 'white',
        borderRadius: '12px',
        fontWeight: 500,
        fontFamily: 'inherit',
        fontSize: '1rem',
        padding: '1rem 1.25rem',
        _hover: {
          _disabled: {
            background: 'black',
          },
        },
      },
      sizes: {
        default: {
          minHeight: '53px',
        },
      },
      variants: {
        primary: {
          bg: 'primary',
          _hover: {
            _disabled: {
              background: 'primary',
            },
          },
        },
        secondary: {
          bg: 'white',
          color: 'primary',
          border: '1px solid',
          borderColor: 'primary',
        },
        black: {
          bg: 'black',
        },
        green: {
          bg: '#0CA789',
        },
        red: {
          bg: '#DA0063',
        },
      },
      defaultProps: {
        variant: 'primary',
        size: 'default',
      },
    },
    Input: defineMultiStyleConfig({
      baseStyle: definePartsStyle({
        field: {
          width: '100%',
          height: '3rem',
          padding: '0.875rem 1rem',
          border: '1.5px solid',
        },
      }),
      variants: {
        default: definePartsStyle({
          field: {
            borderColor: 'black',
          },
        }),
      },
      defaultProps: {
        variant: 'default',
      },
    }),
  },
});
